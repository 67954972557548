<template>
  <ContainerVue
    class="bg-[#e5f4f3] p-4 text-xl border-[#D98888]"
    :class="{ 'bg-[#f5e4e4] border-[#D98888]': is_cost }"
    :style="{ 'border-color': ! is_cost ? '#009285' : '#D98888' }"
  >
    <h1>
      {{ props.label }}
      <span class="font-semibold" :class="props.color">{{ props.value }}</span>
    </h1>
  </ContainerVue>
</template>

<script setup>
import ContainerVue from '@/components/Container.vue'

const props = defineProps({
  label: String,
  color: String,
  value: String,
  is_cost: Boolean
})

</script>
