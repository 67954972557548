<template>
  <div
    class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
  >
    <div class="text-left">
      <p class="mb-2 font-medium text-lg text-gray-800">Permissões</p>
      <p class="text-gray-500 flex">
        As permissões definem as ações e páginas que o usuario terá acesso
      </p>
    </div>

    <div class="lg:col-span-2 mt-3 md:mt-0">
      <PermissionsVue :register="prop.create" />
    </div>
  </div>
</template>

<script setup>
import PermissionsVue from '../components/Permissions.vue'
import { defineProps } from 'vue'

const prop = defineProps({
  create: Boolean
})

</script>

<style></style>
