<template>
  <div>
    <PageTitleVue> Editar Produtos </PageTitleVue>

    <ContainerVue>
      <Form :descriptionTitle="'Atualize seu produto'">
        <CreateButton
          @click="updateDataProduct"
          :icon="'fa-solid fa-boxes-packing'"
          :label="'Atualizar'"
        />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ProductService from '../services/product.service'
import ContainerVue from '@/components/Container.vue'
import Form from './Form.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import * as toast from '@/helpers/useToast'
import { dataProduct } from '../store'
import { useRoute } from 'vue-router'
import { onBeforeMount } from 'vue'
import { storeApp } from '@/store'
import * as yup from '@/validator'

const store = dataProduct
const appStore = storeApp
const productId = useRoute().params.id

const form = yup.object().shape({
  name: yup.string().required().label('Produto').min(3, 'Deve ter no minimo 3 caracteres'),
  amount: yup.string().label('Quantidade').nullable(),
  description: yup.string().nullable(),
  type: yup.object().label('Tipo').nullable(),
  price: yup.string()
    .required('O valor é obrigatório')
    .test(
      'is-greater-than-zero',
      'O valor deve ser maior que zero',
      value => {
        const onlyDigits = value.replace(/[^\d,]/g, '').replace(',', '.')
        return parseFloat(onlyDigits) > 0
      }
    )
})

onBeforeMount(async () => {
  store.commit('reset')
  appStore.state.loading = true
  await getProduct()
  appStore.state.loading = false
})

async function getProduct () {
  store.commit('reset')
  await ProductService.getProductById(productId)
    .then(res => {
      store.commit('updateProductFields', res.data)
    }).catch(err => {
      toast.showToast(
        'Aviso', 
        err.response.data.error, 
        'error'
      )
    })
}

async function updateDataProduct () {
  // return console.log(store.state);

  appStore.state.loading = true

  const validate = (
    await validateFormField(form, store.state)
  )

  if (validate && validate.length > 0) {
    console.log(validate)
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await ProductService.editProduct(store.state.id, store.state)
    .then(res => {
      if (res.data.success) {
        toast.showToast(
          'Sucesso', 
          res.data.success, 
          'success'
        )
      }
    }).catch(err => {
      toast.showToast(
        'Aviso', 
        err.response.data.error, 
        'error'
      )
    }).finally(() => {
      appStore.state.loading = false
    })
}

</script>
