<template>
  <div>
    <PageTitleVue> Cadastro de Clientes </PageTitleVue>

    <ContainerVue>
      <Form :descriptionTitle="'Registrar Cliente'">
        <CreateButton
          @click="newClient"
          :icon="'fa-solid fa-user-plus'"
        />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import ContainerVue from '@/components/Container.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import Form from '../layouts/Form.vue'
import * as yup from '@/validator'
import { dataClient } from '../store'
import { storeApp } from '@/store'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onMounted } from 'vue'
import ClientService from '../services/clients.service'
import * as toast from '@/helpers/useToast'

const appStore = storeApp
const store = dataClient

const form = yup.object().shape({
  name: yup.string().required().label('Nome').min(3).max(100),
  cellphone: yup.string().label('Celular').max(20).nullable(),
  instagram: yup.string().label('Instagram').nullable(),
  facebook: yup.string().label('Facebook').nullable()
})

onMounted(() => {
  store.commit('reset')
})

async function newClient () {
  appStore.state.loading = true

  const validate = (await validateFormField(form, store.state))

  if (validate && validate.length > 0) {
    console.log(validate);
    
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await ClientService.register(store.state)
    .then(res => {
      appStore.state.loading = false
      if (res.data.success) {
        toast.showToast(
          'Sucesso',
          res.data.success,
          'success'
        )
      }
    })
    .catch(err => {
      appStore.state.loading = false
      toast.showToast(
        'Sucesso',
        'Algo deu errado na criação do cliente',
        'success'
      )
    })
}

</script>
