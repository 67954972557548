<template>
  <div>
    <PageTitle> Agendamentos </PageTitle>

    <HeaderSection />

    <ContainerVue
      class="mt-auto bottom-0"
      v-if="hash"
    >
      <div class="text-left p-2 flex justify-between">
        <small class="ml-">
          <span class="w-full text-left font-semibold p-2 text-lg">
            Seu link de agendamento <br>
          </span>
          <a href="javascript:void(0);" @click="copyToClipboard" class="ml-2">
            {{
              isMobile ?
                scheduleUrl.slice(0, 40).concat("...") :
                scheduleUrl
            }}
          </a>
        </small>
        <i
          class="fa-regular fa-copy text-2xl rounded-full pl-1.5 pr-1.5 cursor-pointer mt-auto mb-auto"
          @click="copyToClipboard"
        ></i>
      </div>
    </ContainerVue>
  </div>
</template>

<script setup>
import PageTitle from '@/components/Titles/PageTitle.vue'
import HeaderSection from './pages/HeaderSection.vue'
import ContainerVue from '@/components/Container.vue'
import * as toast from '@/helpers/useToast'
import { computed } from 'vue'

const hash = localStorage.getItem('__schedule_hash')
const scheduleUrl = `
  ${process.env.VUE_APP_BASE_URL}#/agendar-horario/${hash}
`

const isMobile = computed(() => window.innerWidth < 768)

function copyToClipboard () {
  navigator.clipboard.writeText(scheduleUrl)
    .then(() => {
      toast.showToast(
        'BarbApp informa:', 
        'Link copiado para a área de transferência!', 
        'info'
      )
    })
    .catch(err => {
      console.log(err)
    })
}

</script>

<style scoped>
a {
  display: inline;
  color: rgb(30, 0, 255);
  text-decoration: underline;
  cursor: pointer;
}
h1 {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  letter-spacing: 1.1px !important;
}
</style>
