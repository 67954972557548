<template>
  <div>
    <div class="fadeIn first mb-5">
      <img
        :src="appImage"
        alt="BarbApp"
        class="w-32 h-32 mx-auto rounded-full"
      />
    </div>
    <div id="form-fields" class="lg:col-span-2">
      <!-- email -->
      <input
        class="shadow-sm fadeIn second"
        :type="'email'"
        placeholder="Email de acesso"
        v-model="store.email"
        autocomplete="on"
        name="email"
      />
      <br>
      <span name="email" class="text-red-600 text-sm"></span>

      <!-- password -->
      <div class="relative">
        <input
          required
          v-model="store.password"
          name="password"
          :type="inputType"
          class="shadow-sm fadeIn second"
          placeholder="Senha"
          @keyup.enter="signin"
        />
        <br>
        <span name="password" class="text-red-600 text-sm"></span>

        <label
          @click="togglePasswordVisibility"
          class="absolute top-1/2 transform -translate-y-1/2 cursor-pointer"
          :class="[
            isMobile ? 'right-8' : 'right-12'
          ]"
        >
          <i v-if="showPassword" class="fas fa-eye text-gray-600"></i>
          <i v-else class="fas fa-eye-slash text-gray-600"></i>
        </label>
      </div>

      <div class="p-2 flex justify-center">
        <CreateButton
          class="mt-5"
          @click="signin()"
          :label="'Entrar'"
          :icon="'fa-solid fa-arrow-right-to-bracket'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import AuthService from '../services/auth.service'
import router from '@/router'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import * as toast from '@/helpers/useToast'
import { dataAuth } from '../store'
import { storeApp } from '@/store'
import { computed, ref } from 'vue'
import * as yup from '@/validator'

const appImage = require('@/assets/logo/app.png')
const store = dataAuth.state.auth
const showPassword = ref(false)
const inputType = computed(() => (showPassword.value ? 'text' : 'password'))
const isMobile = computed(() => window.innerWidth < 768)

const form = yup.object().shape({
  email: yup.string().required().label('Email').min(3).max(150),
  password: yup.string().required().label('Senha').max(40)
})

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

async function signin () {
  storeApp.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    storeApp.state.loading = false
    return
  }

  await AuthService.login(store)
    .then((res) => {
      storeApp.dispatch('showMenuUserLogged')
      storeApp.dispatch('setUserAuthenticated', true)

      const userPermissions = [...res.data.user.permissions].map(
        (el) => el.key
      )

      localStorage.setItem('__permissions', userPermissions)
      localStorage.setItem('__access', res.data.access_token)
      localStorage.setItem('__uuid', res.data.user.id)
      localStorage.setItem('__user_name', res.data.user.name)
      localStorage.setItem('__company', res.data.user.companies.id)
      localStorage.setItem('__schedule_hash', res.data.user.companies.hash)

      if (res.data.user.companies.photo !== null || res.data.user.companies.photo !== 'null') {
        storeApp.dispatch('setCompanyLogo', res.data.user.companies.photo)
      } else {
        const appDefault = require('@/assets/logo/app.png')
        storeApp.dispatch('setCompanyLogo', appDefault)
      }

      storeApp.state.loading = false

      toast.showToast(
        'Olá!', 
        'Seja bem vindo', 
        'success', 
      )

      router.push('/')
    })
    .catch((err) => {
      if (err.response.status && err.response.status === 401) {
        storeApp.state.loading = false
        return toast.showToast(
          'Aviso', 
          'Usuário ou senha incorretos!', 
          'error', 
        )
      }
    }).catch((err) => {
      storeApp.state.loading = false
      return toast.showToast(
        'Aviso',
        'Algo deu errado!',
        'error'
        )
      })
  }
</script>

<style lang="scss" scoped>
input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #39ace7;
  }
  &:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
}
input[type="text"],
input[type="password"],
input[type="email"] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  &:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  &::placeholder {
    color: #a1a1a1;
  }
}
</style>
