<template>
  <div>
    <PageTitleVue> Novo colaborador </PageTitleVue>

    <ContainerVue>
      <Form id="form-fields" :descriptionTitle="'Dados pessoais'">
        <Password
          :create="true"
          :descriptionTitle="'Senha de acesso'"
          :descriptionSubTitle="'Defina uma senha de acesso para seu colaborador'"
        />
        <ShiftForm
          :descriptionTitle="'Horarios de atendimento'"
          :descriptionSubTitle="'Informe os turnos que esse colaborador estará disponivel para serviços'"
        />
        <PermissionsForm :create="true" />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="newCollaborator"
            :icon="'fa-solid fa-user-plus'"
            :label="'Adicionar'"
          />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import CollabService from '../services/collab.service'
import ContainerVue from '@/components/Container.vue'
import PermissionsForm from './PermissionsForm.vue'
import Password from './PasswordForm.vue'
import ShiftForm from './ShiftForm.vue'
import Form from '../layouts/Form.vue'
import * as yup from '@/validator'
import { dataCollab } from '../store'
import { storeApp } from '@/store'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onBeforeMount } from 'vue'
import * as toast from '@/helpers/useToast'

const appStore = storeApp
const store = dataCollab

const form = yup.object().shape({
  name: yup.string().required().label('Nome').min(3).max(100),
  phone: yup.string().nullable().label('Celular').max(20),
  email: yup.string().required().label('Email').max(100),
  role: yup.string().required().label('Função').max(50),
  password: yup.object().shape({
    newPass: yup.string().required('É necessário informar a nova senha').min(5, 'Deve ter no mínimo 5 caracteres'),
    confirm: yup.string().oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais').required('Por favor confirme a nova senha')
  })
})

onBeforeMount(async () => {
  appStore.state.loading = true
  store.commit('reset')
  await permissions()
  appStore.state.loading = false
})

// busca todas as permissões
async function permissions () {
  await CollabService.getPermissions()
    .then(res => {
      store.state.permissions = res.data
    }).catch(err => {
      toast.showToast(
        'Erro',
        'Algo deu arrado ao buscar dados do funcionário',
        'error'
      )
    })
}

// registra um novo colaborador
async function newCollaborator () {
  appStore.state.loading = true

  // validação dos campos de formulário
  const validate = (await validateFormField(form, store.state))
  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false

    toast.showToast(
      'Aviso',
      'Campos obrigatórios não preenchidos',
      'error'
    )

    return
  }

  const data = Object.assign({}, store.state)

  delete data.userPermissions
  delete data.permissions
  delete data.haircuts
  delete data.password.confirm
  delete data.password.old
  data.password = data.password.newPass

  if ( // se caso exista uma foto, remova e troque pela nova
    store.state.image &&
    typeof store.state.image === 'object' &&
    typeof store.state.imgPath === 'string' &&
    store.state.imgPath !== '[]'
  ) {
    await CollabService.editImage(
      store.state.image,
      store.state.imgPath
    )
      .then((res) => {
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast.showToast(
          'Aviso',
          'Algo deu errado ao subir arquivo!',
          'error'
        )
      })
  } else if ( // adicione uma nova foto
    store.state.image &&
    typeof store.state.image !== 'string'
  ) {
    await CollabService.saveImage(store.state.image)
      .then((res) => {
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast.showToast(
          'Aviso',
          'Algo deu errado ao subir arquivo!',
          'error'
        )
      })
  } else { // passa o caminho da imagem
    // store.state.image = store.state.imgPath
    data.image = store.state.imgPath
  }

  delete data.imgPath

  await CollabService.register(data)
    .then(res => {
      appStore.state.loading = false
      if (res.data.success) {
        toast.showToast(
          'Sucesso',
          res.data.success,
          'success'
        )
      }
    })
    .catch(err => {
      appStore.state.loading = false

      if (err.response.status && err.response.status === 400) {
        return toast.showToast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }

      toast.showToast(
        'Erro',
        err.response.data.error,
        'error'
      )
    })
}

</script>
