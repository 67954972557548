import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/company/'

class CompanyService {
  async getMeCompany (meUuid) {
    return http.get(PATH.concat('me/').concat(meUuid))
  }

  async updateNotificationData (payload) {
    return http.post(PATH.concat('notifications'), payload)
  }

  async getNotificationData (id) {
    return http.get(PATH.concat('notifications/').concat(id).concat('/me'))
  }

  async updateMeCompany (meUuid, companyBody) {
    return http.patch(PATH.concat('me/').concat(meUuid).concat('/edit'), companyBody)
  }

  async saveImage (newImg) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company')
      }
    }

    return imgServe.post('new-file', { file: newImg }, headers)
  }

  async editImage (newImg, imgPath) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company'),
        __company_path: imgPath
      }
    }

    return imgServe.post('replace-file', { file: newImg }, headers)
  }

  async getConfigs () {
    const headers = {
      headers: {
        __company_id: localStorage.getItem('__company')
      }
    }

    return http.get(PATH.concat('configs'), headers)
  }

  async updateConfigs (data) {
    const meUuid = localStorage.getItem('__uuid')
    const headers = {
      headers: {
        __company_id: localStorage.getItem('__company')
      }
    }

    return http.post(PATH.concat('me/').concat(meUuid).concat('/config-edit'), data, headers)
  }
}

export default new CompanyService()
