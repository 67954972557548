<template>
  <div class="px-1 py-2 appearance-none outline-none text-gray-800">
    <Combobox v-model="selected">
      <div class="relative">
        <div
          class="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
        >
          <ComboboxInput
            class="input-field"
            :displayValue="(person) => person?.name || ''"
            @change="query = $event.target.value"
          />
          <ComboboxButton
            class="absolute inset-y-0 right-0 flex items-center justify-end pr-2 w-full"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div
              v-if="filteredPeople.length === 0 && query !== ''"
              class="relative cursor-default select-none py-2 px-4 text-gray-700"
            >
              Nenhum dado encontrado
            </div>

            <ComboboxOption
              v-for="person in filteredPeople"
              :key="person.value"
              :value="person"
              v-slot="{ selected, active }"
            >
              <li
                class="relative cursor-default select-none py-2 ml-auto mr-auto"
                :class="{
                  'bg-teal-600 text-white': active,
                  'text-gray-900': !active,
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ person.name }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                  :class="{ 'text-white': active, 'text-teal-600': !active }"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";

// Props para receber os dados e o valor inicial
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  initialValue: {
    type: [String, Object], // Pode ser o value como string ou o objeto inteiro
    default: null,
  },
});

const emit = defineEmits(["selectedData"]);

// Estado interno
const query = ref("");
const selected = ref(null);

// Inicializa o valor selecionado com base no valor inicial
watch(
  () => props.initialValue,
  (newValue) => {
    if (typeof newValue === "string") {
      // Busca no array `data` onde o value ou name corresponde ao valor recebido
      selected.value =
        props.data.find(
          (item) =>
            item.value.toLowerCase() === newValue.toLowerCase() ||
            item.name.toLowerCase() === newValue.toLowerCase()
        ) || null;
    } else if (typeof newValue === "object") {
      selected.value = newValue;
    }
  },
  { immediate: true }
);

// Filtra os dados com base na query
const filteredPeople = computed(() =>
  query.value === ""
    ? props.data
    : props.data.filter((person) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// Emite o valor selecionado
watch(selected, (newValue) => {
  emit("selectedData", newValue);
});
</script>

<style>
.input-field {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  background-color: transparent;
  transition: border-color 0.3s ease-in-out;
}

.input-field:focus {
  border-bottom: 2px solid #007bff;
}
</style>
