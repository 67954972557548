import { createStore } from 'vuex'

export const dataCollab = createStore({
  state: () => {
    return {
      name: undefined,
      phone: undefined,
      email: undefined,
      role: undefined,
      haircuts: undefined,
      permissions: undefined,
      userPermissions: undefined,
      updatePermissions: undefined,
      password: {
        old: undefined,
        newPass: undefined,
        confirm: undefined
      },
      image: undefined,
      imgPath: undefined,
      turno: {
        shiftHour: undefined, // horarios de atendimento
        is_freela: false, // caso seja freelancer
        freela_in_service: false, // caso seja freelancer verificar se esta ativo hoje
        hourForLunch: undefined,
        returnForLunch: undefined,
        entry_time: undefined,
        closing_time: undefined
      }
    }
  },
  getters: {
    getshiftHour: (state) => state.shiftHour,
    getis_freela: (state) => state.is_freela,
    getfreela_in_service: (state) => state.freela_in_service,
    gethourForLunch: (state) => state.hourForLunch,
    getreturnForLunch: (state) => state.returnForLunch,
    getentry_time: (state) => state.entry_time,
    getclosing_time: (state) => state.closing_time
  },
  mutations: {
    reset (state) {
      state.name = undefined
      state.phone = undefined
      state.email = undefined
      state.role = undefined
      state.haircuts = undefined
      state.permissions = undefined
      state.userPermissions = undefined
      state.updatePermissions = undefined
      state.password.old = undefined
      state.password.newPass = undefined
      state.password.confirm = undefined

      // turnos
      state.turno.shiftHour = undefined
      state.turno.is_freela = false
      state.turno.freela_in_service = false
      state.turno.hourForLunch = undefined
      state.turno.returnForLunch = undefined
      state.turno.entry_time = undefined
      state.turno.closing_time = undefined
    },
    updateCollabFields (state, payload) {
      Object.assign(state, payload)
      state.imgPath = payload.image
    },
    setCollabSettings (state, payload) {
      state.turno = payload
    }
  },
  actions: {
    setCollabSettings ({ commit }, value) {
      commit('setCollabSettings', value)
    }
  }
})
