<template>
  <div>
    <div class="fadeIn first mb-5">
      <img
        :src="appImage"
        alt="BarbApp"
        class="w-32 h-32 mx-auto rounded-full"
      />
    </div>
    <div id="form-fields" class="lg:col-span-2">
      <input
        type="text"
        class="fadeIn second"
        placeholder="Código de confirmação"
        name="code"
        v-model="store.code"
      />
      <br>
      <span name="code" class="text-red-600 text-sm"></span>

      <div class="p-2 flex justify-center fadeIn fourth">
        <CreateButton
          class="mt-5"
          @click="sendCode()"
          :label="'Verificar Código'"
          :icon="'fa-solid fa-arrow-right-to-bracket'"
          :maxSize="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import RestorePassword from './RestorePassword.vue'
import AuthService from '../services/auth.service'
import ForgotPassword from './ForgotPassword.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import * as toast from '@/helpers/useToast'
import { dataAuth } from '../store'
import { storeApp } from '@/store'
import * as yup from '@/validator'
import { markRaw } from 'vue'

const appImage = require('@/assets/logo/app.png')
const store = dataAuth.state.forgotPassword

const form = yup.object().shape({
  code: yup.string().required('Informe o código recebido').label('Código')
})

async function sendCode () {
  if (!store.email) {
    toast.showToast(
      'Aviso',
      'Email não informado, tente novamente',
      'warn'
    )
    dataAuth.dispatch('updateComponentActive', markRaw(ForgotPassword))
  }

  storeApp.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    storeApp.state.loading = false
    return
  }

  await AuthService.sendCodeConfirmation(store)
    .then(res => {
      storeApp.state.loading = false
      if (res.data.success) {
        toast.showToast(
          'Sucesso',
          res.data.message,
          'success'
        )
      }

      dataAuth.dispatch('updateComponentActive', markRaw(RestorePassword))
    }).catch(err => {
      storeApp.state.loading = false
      if (err.response.status && err.response.status === 400) {
        return toast.showToast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }
      if (err.response.status && err.response.status === 401) {
        return toast.showToast(
          'Aviso',
          err.response.data.message,
          'error'
        )
      }
    })
}

</script>

<style lang="scss" scoped>
input[type="text"],
input[type="password"],
input[type="email"] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  &:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.357);
  }
}
</style>
