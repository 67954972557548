<template>
  <ContainerVue class="bg-[#e5f4f3] p-4 text-xl" style="border-color: #009285 !important">
    <h1>
      Total de serviços
      <span class="text-[#009285] font-semibold">
        {{
          formatMoney(store.getters.getTotal)
        }}
      </span>
    </h1>
  </ContainerVue>
</template>

<script setup>
import ContainerVue from '@/components/Container.vue'
import { dataMoviment as store } from '../store'

function formatMoney (val) {
  if (typeof val !== 'number') {
    val = parseFloat(val) || 0
  }

  return val.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}

</script>

<style>

</style>
