<template>
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in dataTable" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.name }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.phone }}
              </span>
              <i v-if="!row.phone" class="text-gray-400">
                não informado
              </i>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.role }}
              </span>
              <i v-if="!row.role" class="text-gray-400">
                não informado
              </i>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span v-if="row.haircuts != null || row.haircuts != undefined" class="pl-2">
                {{ row.haircuts }}
              </span>
              <i v-else class="text-gray-400">
                não informado
              </i>
            </div>
          </td>

          <td class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButtonVue
              :link="url.edit + dataTable[index].id"
              @delete="deleteEmployee(dataTable[index].id)"
            />
            <!-- @getReport="generateReport(dataTable[index].id)" -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ActionsButtonVue from './../../../components/Tables/ActionsButton.vue'
import CollabService from '../services/collab.service'
import Create from '@/components/Buttons/Create.vue'
import * as toast from '@/helpers/useToast'
import { storeApp } from '@/store'

export default {
  name: 'CollabTable',
  components: {
    ActionsButtonVue,
    Create
  },
  props: {
    dataTable: [Object, Array],
    center: { Boolean, default: false }
  },
  data () {
    return {
      url: {
        edit: '/collab/edit/'
      },
      columns: this.dataTable,
      toast,
      showModal: false,
      modalTitle: 'Selecione o relatório que deseja',
      currentEmployee: {}
    }
  },
  methods: {
    // closeModal() {
    //   this.showModal = false
    //   this.currentEmployee = {}
    // },
    // showReport(employeeData) {
    //   this.currentEmployee = employeeData
    //   this.showModal = true
    // },
    // async generateReport (id) {
    //   storeApp.state.loading = true

    //   const url = `${process.env.VUE_APP_API_URL}${PATH}reports/${id}`
    //   const report = new PdfCreate(url)

    //   return await report.fetchPdf().finally(() => {
    //     storeApp.state.loading = false
    //   })
    // },
    // async generateBadges (id) {
    //   storeApp.state.loading = true

    //   const url = `${process.env.VUE_APP_API_URL}${PATH}${id}/badges`
    //   const report = new PdfCreate(url)

    //   return await report.fetchPdf().finally(() =>{
    //     storeApp.state.loading = false
    //   })
    // },
    deleteEmployee (id) {
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente remover esse colaborador ?')
      if (confirm) {
        CollabService.deleteCollaborator(id).then(res => {
          if (res.data.success) {
            this.toast.showToast(
              'Sucesso',
              res.data.success,
              'success'
            )
          }
          window.location.reload()
        }).catch(err => {          
          this.toast.showToast(
            'Aviso',
            err.response.data.error,
            'error'
          )
        })
      }
      storeApp.state.loading = false
    }
  }
}
</script>
