<!-- PÁGINA DO CLIENTE -->
<template>
  <div class="relative flex flex-col items-center min-h-screen bg-cover bg-center main">
    <div class="absolute inset-0 bg-gradient-to-t from-black to-[#0000008a]"></div>

    <div class="relative z-10 flex flex-col items-center px-4 py-8 w-full max-w-md text-center text-white">
      <img :src="companyLogo" alt="BarbApp" class="w-36 h-36 mx-auto mb-6 rounded-full" />

      <h1 class="text-2xl font-bold mb-2">Agende seu horário</h1>
      <p class="text-sm mb-4">
        {{ subTitle }}
      </p>

      <!-- opções de agendamento e confirmação de agendamento -->
      <div class="space-y-4 w-full">
        <Transition mode="out-in">
          <component
            :is="currentComponent"
            @open-modal="openModal"
          />
        </Transition>
      </div>

      <!-- Botão de confirmação -->
      <button
        v-if="step === 'SelectedOptionsStep'"
        @click="goToConfirmation"
        class="w-full py-3 mt-6 font-semibold rounded-lg cursor-pointer"
        :class="buttonClasses"
        :disabled="isButtonDisabled"
      >
        Confirmar agendamento
      </button>

      <div class="w-full" v-if="step === 'ConfirmationStep'">
        <button
          @click="toSchedule"
          class="w-full py-3 mt-6 bg-white text-black font-semibold rounded-lg transition transform hover:scale-105 hover:shadow-lg hover:-translate-y-1"
        >
          Agendar
        </button>

        <button
          @click="stepBack"
          class="w-full py-3 mt-6 bg-[#21242b] text-white font-semibold rounded-lg transition transform hover:scale-105 hover:shadow-lg hover:-translate-y-1"
        >
          Voltar
        </button>
      </div>
    </div>

    <!-- Modal Dinâmico -->
    <BaseModal :isOpen="isModalOpen" @update:isOpen="closeModal">
      <component :is="activeComponent" />
    </BaseModal>
  </div>
</template>

<script setup>
import DateTimeSelectionModal from '../layouts/DateTimeSelectionModal.vue'
import ServiceSelectionModal from '../layouts/ServiceSelectionModal.vue'
import BarberSelectionModal from '../layouts/BarberSelectionModal.vue'
import SelectedOptionsStep from '../layouts/SelectedOptionsStep.vue'
import ConfirmationStep from '../layouts/ConfirmationStep.vue'
import ScheduleService from '../services/schedule.service'
import BaseModal from '@/components/Modals/BaseModal.vue'
import { markRaw, ref, computed, onMounted } from 'vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { dataSchedule } from '../store'
import { storeApp } from '@/store'
import * as yup from '@/validator'
import { useRoute } from 'vue-router'
import * as toast from '@/helpers/useToast'
import router from '@/router'

const step = ref('SelectedOptionsStep')
const activeComponent = ref(null)
const store = dataSchedule.state
const appStore = storeApp
const route = useRoute()

const isButtonDisabled = computed(() => !dataSchedule.getters.checkSchedulingValues)
const subTitle = computed(() => dataSchedule.getters.getSubTitlePage)
const defaultLogo = require('@/assets/logo/app.png')
const companyLogo = computed(() => {
  if (
    !dataSchedule.getters.getCompanyLogo ||
    dataSchedule.getters.getCompanyLogo == ''
  ) {
    return defaultLogo
  }

  return ref(process.env.VUE_APP_IMAGE_SERVE.concat('storage/')).value + dataSchedule.getters.getCompanyLogo
})
const isModalOpen = computed(() => {
  const modalIsOpen = dataSchedule.getters.getModalState
  return modalIsOpen
})
const buttonClasses = computed(() => {
  return isButtonDisabled.value
    ? 'bg-[#21242b] text-gray-400'
    : 'transition transform hover:scale-105 hover:shadow-lg hover:-translate-y-1 bg-white text-black'
})
const currentComponent = computed(() => {
  return step.value === 'SelectedOptionsStep' ? markRaw(SelectedOptionsStep) : markRaw(ConfirmationStep)
})
const form = yup.object().shape({
  client_schedule: yup.object().shape({
    name: yup.string().required('Precisamos saber seu nome').label('Nome').max(100)
  })
})

function openModal (type) {
  dataSchedule.commit('activateModal')

  if (type === 'barbeiro') {
    activeComponent.value = markRaw(BarberSelectionModal)
  } else if (type === 'servico') {
    activeComponent.value = markRaw(ServiceSelectionModal)
  } else if (type === 'dataHora') {
    activeComponent.value = markRaw(DateTimeSelectionModal)
  }
}

function closeModal () {
  dataSchedule.commit('disableModal')
  activeComponent.value = null
}

function goToConfirmation () {
  if (dataSchedule.getters.checkSchedulingValues !== false) {
    step.value = 'ConfirmationStep'
    dataSchedule.dispatch('updateDescriptionPage', 'Confirme os dados para agendamento')
  }
}

// volta o conteudo para o anterior
function stepBack () {
  dataSchedule.dispatch('updateDescriptionPage', 'Escolha os serviços que deseja e agende-os no horário que preferir.')
  step.value = 'SelectedOptionsStep'
}

// pega os dados da empresa para passar ao cliente
async function getDataCompany () {
  await ScheduleService.getDataClientSchedule(route.params.cId)
    .then(res => {
      const response = res.data

      if (response.success) {
        dataSchedule.dispatch('setCompanyValues', response.data)
      }
    }).catch(err => {
      toast.showToast(
        'Aviso',
        'Nenhuma barbearia encontrada', 
        'error'
      )

      router.push({ name: 'login' })
    })
}

// agenda um horario
async function toSchedule () {
  appStore.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  const data = {
    client_name: store.client_schedule.name,
    ...store.client_schedule.services
  }

  await ScheduleService.registerSchedule(data, route.params.cId)
    .then(res => {
      if (res.data.success) {
        toast.showToast(
          'Sucesso',
          res.data.message, 
          'success'
        )

        // requisições em segundo plano
        Promise.allSettled([
          ScheduleService.verifyUnavailableTimes(data.date, route.params.cId)
            .then(response => {
              dataSchedule.dispatch('updateUnavailableTimes', response.data)
            }),

          ScheduleService.getAllSchedules()
            .then(res => {
              const response = res.data
              if (response.success) {
                dataSchedule.dispatch('updateSchedulesFields', response.data)
              }
            })
        ])

        // limpar campos e voltar para o component anterior ativo
        stepBack()
        dataSchedule.dispatch('resetClientScheduleFields')
        appStore.state.loading = false

        if (data.barber.phone) { redirectToWhatsApp(data) }
      }
    }).catch(err => {
      appStore.state.loading = false
      if (err.response.data.status && err.response.data.status === 404) {
        return toast.showToast(
          'Aviso', 
          err.response.data.message, 
          'warn'
        )
      }
      if (err.response.data.status && err.response.data.status === 400) {
        return toast.showToast(
          'Aviso', 
          err.response.data.message, 
          'warn'
        )
      }

      return toast.showToast(
        'Aviso', 
        'Algo deu errado ao fazer agendamento', 
        'error'
      )
    })
}

function isIOS () {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

function redirectToWhatsApp (data) {
  const formattedPhone = data.barber.phone
    .replace(/\D/g, '')
    .replace(/^0/, '')
    .replace(/^/, '55')

  const hours = data.hour || '00:00'
  const date = computed(() => {
    const storedDate = data.date
    const [year, month, day] = storedDate.split('-')

    return `${day}/${month - 1}`
  })

  const services = data.services && data.services.length > 0
    ? data.services.map(service => service.name).join(', ')
    : 'serviços não informados'

  const message = `Fala *${data.barber.name.split(' ')[0]}*,%0AAqui é o *${data.client_name.trimEnd()}*%0A%0AMarquei um horário com você às *${hours}* (${date.value}) para fazer os seguintes serviços:%0A*${services}*%0A%0AMuito obrigado e até breve!`

  const whatsappUrl = `https://wa.me/${formattedPhone}?text=${message}`

  if (isIOS()) {
    return location.href = whatsappUrl
  }

  return window.open(whatsappUrl, '_blank')
}

onMounted(async () => {
  appStore.state.loading = true
  dataSchedule.dispatch('resetClientScheduleFields')
  dataSchedule.dispatch('updateDescriptionPage', 'Escolha os serviços que deseja e agende-os no horário que preferir.')

  await getDataCompany()

  appStore.state.loading = false
})

</script>

<style>
.main {
  background-image: url(@/assets/logo/barbapp-auth-background.webp);
}

button:focus {
  outline: none;
}

.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
