<template>
  <div
    class="border mt-1 rounded max-h-[90vh] p-2 text-xl min-w-[22.7%] mx-1 text-left px-4 py-3"
  >
    <!-- :class="[
      totalAmount ? 'bg-[#E5F4F3] border-[#009285]' : '',
      totalCosts ? 'bg-[#F5E4E4] border-[#B45A5A]' : '',
      totalServices ? 'bg-[#F5F3E4] border-[#B4955A]' : '',
      schedules ? 'bg-[#e5ebf4] border-[#004b92]' : '',
    ]" -->
    <h1>
      <span 
        :class="[totalAmount ? 'text-[#009285]' : '', totalCosts ? 'text-[#B45A5A]' : '', totalServices ? 'text-[#B49B5A]' : '', schedules ? 'text-[#004b92]' : '']"
      >
        {{ props.value }}
      </span> <br>
      <div class="flex flex-col">
        <span class="text-gray-500 text-base">
          {{ props.label }} 
        </span>
        <small class="text-gray-400 text-xs">
          Neste mes
        </small>
      </div>
    </h1>
  </div>
</template>

<script setup>
const props = defineProps({
  label: String,
  value: [String, Number],
  totalAmount: { Boolean, default: false },
  totalCosts: { Boolean, default: false },
  totalServices: { Boolean, default: false },
  schedules: { Boolean, default: false }
})

</script>
