<template>
  <div class="flex font-sans tracking-wide relative">

    <!-- Botão de Menu Mobile -->
    <button
      v-if="isMobile"
      @click="expandedMenu(! storeApp.state.menuOpen)"
      class="absolute top-4 left-4 z-50 p-2 rounded-xl focus:outline-none transition-all duration-300 ease-in-out"
      :class="[
        isMobile && storeApp.state.menuOpen ? 'ml-64 set-max-index' : '',
        storeApp.state.menuOpen ? 'text-white' : 'border border-gray-300 text-gray-500 bg-white'
      ]"
    >
      <!-- Ícones do Menu -->
      <MenuIcon v-if="! storeApp.state.menuOpen" class="h-6 w-6" />
      <XIcon v-else class="h-10 w-10" />
    </button>

    <!-- Menu lateral -->
    <div
      :class="[
        'bg-[#fff] h-screen flex flex-col transition-all duration-300 ease-in-out rounded-r-2xl border z-40',
        isMobile && storeApp.state.menuOpen ? 'w-64 absolute set-max-index' : '',
        isMobile && !storeApp.state.menuOpen ? '-ml-24 fixed' : '',
        isDesktop && storeApp.state.menuOpen ? 'w-64' : '',
        isDesktop && !storeApp.state.menuOpen ? 'w-20' : '',
      ]"
      @mouseover="expandedMenu(true)"
      @mouseleave="expandedMenu(false)"
    >
      <!-- Logo e Título -->
      <div
        class="bg-[#f8f8f8] p-5 text-center border-b border-[#e4e4e4] rounded-r-2xl rounded-br-none"
        :class="[
          isMobile && storeApp.state.menuOpen ? 'p-0' : ''
        ]"
      >
        <div
          :class="[
            'justify-end text-left',
            isMobile && storeApp.state.menuOpen ? 'block p-2' : 'flex'
          ]"
        >
          <img
            :key="companyLogo"
            :src="menuLogo"
            alt="BarbApp"
            class="w-10 h-10 mx-auto rounded-full"
            :class="[
              isMobile && storeApp.state.menuOpen ? 'min-w-24 w-24 h-24' : '',
              isDesktop && storeApp.state.menuOpen ? 'min-w-10' : '',
            ]"
          />
          <p
            class="w-full mt-auto mb-auto font-semibold"
            :class="[
              !storeApp.state.menuOpen ? 'hidden' : 'block',
              isMobile && storeApp.state.menuOpen ? 'text-center mt-2' : 'ml-5'
            ]"
          >
            {{ userName }}
          </p>
        </div>
      </div>

      <!-- Opções menu -->
      <section class="flex-1 overflow-y-auto">
        <div class="p-3 pb-2 pl-0 pr-0" :class="[!storeApp.state.menuOpen ? 'text-center' : 'text-left']">
          <p :class="[!storeApp.state.menuOpen ? 'ml-0' : 'ml-5']">
            Menu
          </p>
        </div>
        <nav>
          <ul class="pr-3 pl-3">
            <!-- menus -->
            <div
              v-for="(item, index) in setLinksByPermissions()"
              :key="index"
              class="text-lg"
            >
              <Itens :dataRoute="item" />
            </div>
          </ul>
        </nav>
      </section>

      <!-- Footer menu -->
      <section class="bg-[#f8f8f8] border-t border-[#e4e4e4] rounded-br-2xl rounded-r-none">
        <div class="p-3 pb-2 pl-0 pr-0" :class="[!storeApp.state.menuOpen ? 'text-center' : 'text-left']">
          <p :class="[!storeApp.state.menuOpen ? 'ml-0' : 'ml-5']">
            Outros
          </p>
        </div>
        <nav>
          <ul class="pr-3 pl-3">
            <div
              v-for="(item, index) in exit"
              :key="index"
              class="text-lg"
            >
              <Itens :dataRoute="item" />
            </div>
          </ul>
        </nav>
      </section>
    </div>
    <!-- {{ menuLogo }} -->
    <!-- content -->
    <div
      class="flex-1 max-h-[100vh] p-2 overflow-y-auto overflow-x-auto"
      @click="screenClickMenu()"
    >
      <MenuCoverage
        v-if="isMobile && storeApp.state.menuOpen"
        :key="storeApp.state.setMenuCoverage"
      />
      <slot />
    </div>
  </div>
</template>

<script setup>
import Itens from './Itens.vue'
import {
  HomeIcon,
  LogoutIcon,
  ScissorsIcon, // Cortes
  ClipboardCheckIcon, // Menu Serviços
  UsersIcon, // Clientes, Colaboradores
  ChartBarIcon, // Menu Gestão
  ShoppingBagIcon, // Produtos
  CurrencyDollarIcon, // Movimento
  CalculatorIcon, // Custos
  CogIcon, // Configurações
  UserCircleIcon, // Meu perfil
  OfficeBuildingIcon, // Minha Empresa
  CalendarIcon,
  MenuIcon,
  XIcon,
  XCircleIcon
} from '@heroicons/vue/outline'
import { storeApp } from '@/store'
import { ref, computed, watch } from 'vue'
import MenuCoverage from './ActivatedMenu.vue'

const companyLogo = localStorage.getItem('__company_logo')

const permissions = localStorage.getItem('__permissions')
const userName = localStorage.getItem('__user_name')
const isMobile = computed(() => window.innerWidth < 768)
const isDesktop = computed(() => window.innerWidth >= 768)
const menuLogo = companyLogo == 'null' || companyLogo == '[]' || companyLogo == null || companyLogo == 'undefined' || companyLogo == null ? require('@/assets/logo/app.png') : ref(process.env.VUE_APP_IMAGE_SERVE.concat('storage/')).value + companyLogo

const exit = ref([
  // {
  //   label: "Meu perfil",
  //   route: "/profile",
  //   icon: UserCircleIcon,
  // },
  {
    label: 'Sair',
    route: '/logout',
    icon: LogoutIcon
  }
])
const links = ref([
  {
    label: 'Inicio',
    route: '/',
    icon: HomeIcon,
    key: 'view_dashboard'
  },
  {
    title: 'Serviços',
    icon: ClipboardCheckIcon,
    subMenu: [
      {
        label: 'Cortes',
        route: '/releases',
        icon: ScissorsIcon
      },
      {
        label: 'Agendamentos',
        route: '/scheduling',
        icon: CalendarIcon,
        key: ['view_schedulings', 'view_my_schedulings']
      }
    ]
  },
  {
    title: 'Gestão',
    icon: ChartBarIcon,
    subMenu: [
      {
        label: 'Clientes',
        route: '/clients',
        icon: UsersIcon,
        key: 'view_clients'
      },
      {
        label: 'Produtos',
        route: '/products',
        icon: ShoppingBagIcon,
        key: 'view_products'
      },
      {
        label: 'Movimento',
        route: '/moviment',
        icon: CurrencyDollarIcon,
        key: 'view_haircuts'
      },
      {
        label: 'Custos',
        route: '/costs',
        icon: CalculatorIcon,
        key: 'view_costs'
      }
    ]
  },
  {
    title: 'Configurações',
    icon: CogIcon,
    subMenu: [
      {
        label: 'Colaboradores',
        route: '/collab',
        icon: UsersIcon,
        key: 'view_permissions'
      },
      {
        label: 'Minha Empresa',
        route: '/company',
        icon: OfficeBuildingIcon,
        key: 'view_company'
      },
      {
        label: 'Meu perfil',
        route: '/profile',
        icon: UserCircleIcon
      }
    ]
  }
])

// const imageLogo = computed(() => {
//   if (localStorage.getItem('__company_logo') && localStorage.getItem('__company_logo') !== null) { return imgUrl.value + localStorage.getItem('__company_logo') }

//   return imgDefault
// })

function screenClickMenu () {
  if (storeApp.state.menuOpen == true && isMobile.value) { storeApp.dispatch('setMenuExpanded', false) }
}

function expandedMenu (value) {
  storeApp.dispatch('setMenuExpanded', value)
}

function setLinksByPermissions () {
  if (!permissions) {
    return
  }

  return links.value
    .map((link) => {
      // Filtrando subMenus, se existirem
      if (link.subMenu) {
        const filteredSubMenu = link.subMenu.filter((subLink) => {
          if (!subLink.key) return true

          // Verifica se a key é um array ou uma string
          if (Array.isArray(subLink.key)) {
            // Se for um array, retorna true se qualquer valor estiver em permissions
            return subLink.key.some(key => permissions.includes(key))
          } else {
            // Se for uma string, verifica normalmente
            return permissions.includes(subLink.key)
          }
        })

        // Retorna o link apenas se tiver pelo menos um subMenu válido
        if (filteredSubMenu.length > 0) {
          return {
            ...link,
            subMenu: filteredSubMenu
          }
        } else {
          return null
        }
      }

      // Verificação para links principais (sem subMenu)
      if (!link.key) return link

      // Verifica se a key é um array ou uma string
      if (Array.isArray(link.key)) {
        // Se for um array, retorna true se qualquer valor estiver em permissions
        if (link.key.some(key => permissions.includes(key))) {
          return link
        }
      } else {
        // Se for uma string, verifica normalmente
        if (permissions.includes(link.key)) {
          return link
        }
      }

      return null
    })
    .filter((link) => link !== null)
}
</script>

<style scoped>
.set-max-index {
  z-index: 1001 !important;
}
</style>
