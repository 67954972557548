<template>
    <div class="p-6 flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div class="text-left mb-2">
                <p class="mb-2 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  Campos com <span class="text-red-500">*</span> são obrigatórios
                </p>
              </div>

              <div id="form-fields" class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <!-- nome -->
                  <div class="md:col-span-3">
                    <BaseInput
                      name="name"
                      :info="'Informe o nome do cliente'"
                      v-model="store.name"
                      :required-field="true"
                      label="Nome do cliente"
                      type="text"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.name = null"
                        />
                      </template>
                    </BaseInput>

                    <span name="name" class="text-red-600 text-sm" />
                  </div>

                  <!-- telefone -->
                  <div class="md:col-span-2">
                    <BaseInput
                      name="cellphone"
                      :info="'Número para contato'"
                      v-mask="'(##) # ####-####'"
                      v-model="store.cellphone"
                      label="Telefone"
                      type="text"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.cellphone = null"
                        />
                      </template>
                    </BaseInput>

                    <span name="store.cellphone" class="text-red-600 text-sm" />
                  </div>

                  <!-- instagram -->
                  <div class="md:col-span-3">
                    <BaseInput
                      name="function"
                      :info="'Tirou uma foto ? Marque ele no insta 🚀'"
                      v-model="store.instagram"
                      label="Instagram"
                      type="text"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.instagram = null"
                        />
                      </template>
                    </BaseInput>

                    <span name="store.instagram" class="text-red-600 text-sm" />
                  </div>

                  <!-- facebook -->
                  <div class="md:col-span-2">
                    <BaseInput
                      name="facebook"
                      :info="'Siga-o no Facebook'"
                      v-model="store.facebook"
                      label="Facebook"
                      type="text"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.facebook = null"
                        />
                      </template>
                    </BaseInput>

                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import BaseInput from '@/components/Inputs/BaseInput.vue'
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataClient } from '../store/'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    BaseInput
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataClient.state

    return {
      store,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  }
}
</script>
