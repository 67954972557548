import SchedulingView from '../SchedulingView.vue'
import ClientSchedulingView from './../pages/ClientSchedulingView.vue'

export const schedulingRoutes = [
  {
    path: '/scheduling',
    name: 'scheduling',
    component: SchedulingView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: ['view_schedulings', 'view_my_schedulings']
    }
  }
]

export const clientSchedulingLink = [
  {
    path: '/agendar-horario/:cId',
    name: 'client-scheduling',
    component: ClientSchedulingView
  }
]
