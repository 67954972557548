import { http } from '@/http'

const PATH = 'app/'

class HomeService {
  async getDataChartTable (month = null, year = null, limit = 6) {
    const params = {
      month: month,
      year: year,
      limit: limit,
    };

    return http.get(PATH.concat('worker-performance'), { params })
  }
}

export default new HomeService()
