<template>
  <div>
    <PageTitleVue>
      Movimentações
    </PageTitleVue>

    <!-- gerar modal com mais detalhes -->
    <Total />

    <!-- adicionar card para listar o total desse mês -->
    <!-- <Total /> -->

    <Filter />

    <section v-if="applyedFilters.length > 0">
      <p>
        Filtros:
      </p>

      <div v-if="applyedFilters?.length > 0" class="mb-2 w-full p-1">
        <div
          class="inline-flex flex-wrap"
          v-for="(item, index) in applyedFilters"
          :key="index"
        >
          <div
            class="ml-1 mt-2 p-2 border border-gray-200 rounded bg-gray-50 inline-block"
          >
            {{ item.key }}: {{ item.value }}
          </div>

          <button
            class="flex items-center justify-center bg-red-500 text-white p-2 rounded-full -ml-4 -mt-1 h-5 w-5 "
            @click="removeItem(item.key)"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

    </section>

    <ContainerVue>
      <Paginate :items="store.state.haircut" :items-per-page="10" @reload="getMoviment">
        <thead>
          <tr>
            <TableHeadFieldVue>
              <span class="mb-1.5"> Serviço / Produto </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Cliente </span>
            </TableHeadFieldVue>
            
            <TableHeadFieldVue>
              <span class="mb-1.5"> Valor </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Pagamento </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Realizado por </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue :textCenter="false">
              <span class="mb-1.5"> Realizado em </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </Paginate>
      <FloatCreateVue
        :link="'releases'"
        :menuActive="'Serviços'"
      />
    </ContainerVue>
  </div>
</template>

<script setup>
import TableHeadFieldVue from '@/components/Tables/TableHeadField.vue'
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import MovimentService from './services/moviment.service'
import ContainerVue from '@/components/Container.vue'
import Paginate from './components/Paginate.vue'
import Total from './components/TotalAmount.vue'
import Filter from './components/Filter.vue'
import moment from 'moment'
import * as toast from '@/helpers/useToast'
import { dataMoviment } from './store'
import { storeApp } from '@/store'
import { onMounted } from 'vue'

const appStore = storeApp
const store = dataMoviment
const applyedFilters = store.getters.getApplyedFilters

onMounted(async () => {
  await getMoviment()
  store.commit('restoreFilters')
})

function removeItem (key) {
  store.dispatch('removeSelectedFilter', key)
}

function formatMethod (method) {
  switch (method) {
    case 'credit_card':
      return 'CARTÃO DE CRÉDITO'
      break

    case 'debit_card':
      return 'CARTÃO DE DÉBITO'
      break

    case 'pix':
      return 'PIX'
      break

    case 'money':
      return 'DINHEIRO'
      break

    case 'signature':
      return 'ASSINATURA'
      break

    default:
      return 'DINHEIRO'
      break
  }
}

async function getMoviment () {
  store.commit('reset')
  appStore.state.loading = true

  await MovimentService.getMoviments()
    .then(res => {
      store.state.haircut.values = []

      if (res.data.length > 0) {
        res.data.forEach(el => {
          store.state.haircut.push(
            {
              id: el.id,
              name: el.user.name,
              product: el.products.name,
              client: el.clients?.name ? el.clients?.name : el?.client_name ? el?.client_name : 'não informado',
              price: el.products.price,
              method: formatMethod(el.method),
              total: el.total,
              amount: el.amount,
              create: moment(el.created_at).format('DD/MM/YY')
            }
          )
        })
      } else {
        toast.showToast(
          'BarbApp informa:', 
          'Ainda não há movimentações', 
          'info'
        )
      }
      appStore.state.loading = false
    }).catch(err => {
      appStore.state.loading = false

      toast.showToast(
        'Aviso', 
        err?.response?.data?.error, 
        'error'
      )
    })
}

</script>
