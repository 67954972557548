<template>
  <div class="flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <!-- <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6"> -->
        <div class="md:pl-8 md:pr-8 mb-6">
          <div class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.imgPath"
                  alt="Logo"
                  class="mt-8 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <!-- nome -->
                <div class="md:col-span-5">
                  <BaseInput
                    v-model="store.company.name"
                    name="company.name"
                    :info="'O nome do seu estabelecimento'"
                    label="Noma da empresa"
                    type="text"
                    :required-field="true"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.name = undefined"
                      />
                    </template>
                  </BaseInput>

                  <span name="company.name" class="text-red-600 text-sm" />
                </div>

                <!-- CNPJ -->
                <div class="md:col-span-2">
                  <BaseInput
                    v-model="store.company.cnpj"
                    name="company.cnpj"
                    v-mask="'##.###.###/0001-##'"
                    :info="'O PJ da sua empresa'"
                    label="CNPJ"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton @click="store.company.cnpj = undefined" />
                    </template>
                  </BaseInput>
                </div>

                <!-- cidade -->
                <div class="md:col-span-2">
                  <BaseInput
                    v-model="store.company.city"
                    name="company.cnpj"
                    :info="'Informe a cidade da sua barbearia'"
                    label="Cidade"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton @click="store.company.city = undefined" />
                    </template>
                  </BaseInput>
                </div>

                <!-- bairro -->
                <div class="md:col-span-1">
                  <BaseInput
                    v-model="store.company.country"
                    name="company.country"
                    :info="'Informe o bairro'"
                    label="Bairro"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.country = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- rua -->
                <div class="md:col-span-2">
                  <BaseInput
                    v-model="store.company.address"
                    name="company.address"
                    :info="'Informe o Endereço'"
                    label="Rua"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.address = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- numero -->
                <div class="md:col-span-2">
                  <BaseInput
                    v-model="store.company.number"
                    name="company.number"
                    :info="'Número'"
                    label="Nº"
                    type="number"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.company.number = undefined"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- imagem -->
                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="Logo"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <!-- imagem -->
                <div class="md:col-span-5 mt-4 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[10rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <i class="fa-solid fa-building text-blueGray-600"></i>
                    Logo da Empresa
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from './../component/CleanInputButton.vue'
import InfoVue from './../../../components/tooltip/InfoVue.vue'
import { dataUser } from '../store/'
import * as toast from '@/helpers/useToast'
import BaseInput from '@/components/Inputs/BaseInput.vue'

export default {
  components: {
    CleanInputButton,
    InfoVue,
    BaseInput
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataUser.state
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

    return {
      store,
      toast,
      fileUrl,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.company.photo = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.company.photo = undefined
          return this.toast.showToast(
            'Aviso', 
            'Tipo de arquivo não é válido !', 
            'error'
          )
        }
      }
    }
  }
}
</script>
