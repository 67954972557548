<template>
  <div class="rounded-lg w-full max-w-md bg-[#21242b]">
    <div
      class="agendamento-card flex justify-between items-center rounded-lg shadow-md"
    >
      <div class="w-full">
        <div class="w-full relative pt-2 pb-2" id="bg-barber">
          <div
            class="absolute inset-0 bg-gradient-to-t from-white to-transparent"
          ></div>
          <img
            :src="
              selectedItem.barber_image ?
              imgUrl + selectedItem.barber_image :
              defaultBarberImage
            "
            alt="Foto do barbeiro"
            class="w-20 h-20 relative z-10 rounded-full mr-auto ml-auto border-4 border-[#21242b]"
          />
        </div>

        <div class="flex-1 p-3 pt-4 max-h-[65vh] overflow-y-auto" id="form-fields">
          <label class="font-medium text-sm text-gray-400">Barbeiro selecionado</label> <br />
          <label class="text-base font-medium text-white">
            {{ selectedItem.barber_name }}
          </label>

          <div class="flex text-sm text-gray-400 pt-5 w-full justify-between">
            <!-- Horário -->
            <div class="w-full">
              <label class="font-medium text-sm">Horário</label><br />
              <label class="text-base font-medium text-white">
                {{ selectedItem.horario }}
              </label>
            </div>
            <!-- Serviços -->
            <div class="w-full">
              <label class="font-medium text-sm">Serviços</label><br />
              <label
                class="text-base font-medium text-white"
              >
                {{ selectedItem.servico }}
              </label>
            </div>

            <!-- Valor -->
            <div class="w-full">
              <label class="font-medium text-sm">Valor</label><br />
              <label class="text-[#6fb171] font-semibold text-base">
                {{
                  selectedItem.preco.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </label>
            </div>
          </div>

          <!-- Cliente -->
          <br />
          <label class="font-medium text-sm text-gray-400">Agendado por:</label>
          <div class="input-wrapper">
            <input
              name="selectedItem.cliente"
              class="input-box"
              type="text"
              disabled
              v-model="selectedItem.cliente"
            />
            <label class="underline"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  selectedItem: {
    type: Object || Array,
    required: true
  }
})

const defaultBarberImage = require('@/assets/default_profile.webp')
const imgUrl = ref(process.env.VUE_APP_IMAGE_SERVE.concat('storage/'))

</script>

<style scoped lang="scss">
.input-wrapper {
  position: relative;
  width: 250px;
  margin: auto auto 20px auto;
}

.input-box {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: 28px !important;
  font-weight: 800 !important;
  text-align: center !important;
  letter-spacing: -1.12px !important;

  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  text-align: center;
  color: #fff;
  width: 100%;
  background-color: transparent;
  transition: border-color 0.3s ease-in-out;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #08AEEA;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.input-box:focus {
  border-color: #08AEEA;
  outline: none;
}

.input-box:focus + .underline {
  transform: scaleX(1);
}
#bg-barber {
  background-image: url(@/assets/themes/barber-dark-theme.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

#bg-barber::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
</style>
