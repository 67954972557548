<template>
    <div class=" flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>

          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

              <div class="text-left">
                <p class="mb-2 font-medium text-lg text-gray-800">
                  {{ props.descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  Atualize sua senha de acesso ao BarbApp
                </p>
              </div>

              <div class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <!-- password.old -->
                  <div v-show="! props.create" class="md:col-span-3">
                    <BaseInput
                      type="password"
                      name="password.old"
                      v-model="store.password.old"
                      :info="'Sua senha atual'"
                      label="Senha atual"
                      :required-field="true"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.old = null"
                        />
                      </template>
                    </BaseInput>
                    <span name="password.old" class="text-red-600 text-sm" />
                  </div>

                  <!-- password.newPass -->
                  <div :class="{ 'md:col-span-2': ! create, 'md:col-span-5': create }">
                    <BaseInput
                      :type="inputTypePassowrd"
                      name="password.newPass"
                      v-model="store.password.newPass"
                      :info="'Defina uma nova senha'"
                      label="Nova senha"
                      :required-field="true"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.newPass = null"
                        />

                        <label
                          @click="togglePasswordVisibility"
                          class="top-2 cursor-pointer"
                        >
                          <i v-if="showPassword" class="fas fa-eye text-gray-600"></i>
                          <i v-else class="fas fa-eye-slash text-gray-600"></i>
                        </label>
                      </template>
                    </BaseInput>
                    <span name="password.newPass" class="text-red-600 text-sm" />
                  </div>

                  <!-- password.confirm -->
                  <div class="md:col-span-5">
                    <BaseInput
                      :type="inputTypePassowrdConfirm"
                      name="password.confirm"
                      v-model="store.password.confirm"
                      :info="'Confirme a nova senha'"
                      label="Nova senha"
                      :required-field="true"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.confirm = null"
                        />

                        <label
                          @click="togglePasswordConfirmVisibility"
                          class="top-2 cursor-pointer"
                        >
                          <i v-if="showPasswordConfirm" class="fas fa-eye text-gray-600"></i>
                          <i v-else class="fas fa-eye-slash text-gray-600"></i>
                        </label>
                      </template>
                    </BaseInput>

                    <span name="password.confirm" class="text-red-600 text-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>

        </div>
      </div>
    </div>
  </template>

<script setup>
import CleanInputButton from '../component/CleanInputButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { dataUser } from '../store'
import { computed, ref } from 'vue'

const store = dataUser.state
const showPassword = ref(false)
const showPasswordConfirm = ref(false)

const props = defineProps({
  descriptionTitle: String,
  create: Boolean
})

const inputTypePassowrd = computed(() => (showPassword.value ? 'text' : 'password'))
const inputTypePassowrdConfirm = computed(() => (showPasswordConfirm.value ? 'text' : 'password'))

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

const togglePasswordConfirmVisibility = () => {
  showPasswordConfirm.value = !showPasswordConfirm.value
}

</script>
