<template>
  <div>
    <PageTitleVue> Meus dados </PageTitleVue>
    <ContainerVue>
      <Form id="form-fields" :description-title="'Editar perfil'">
        <PasswordFormVue :create="false" :description-title="'Altere sua senha de acesso'" />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="editMe"
            :icon="'fa-solid fa-check'"
            :label="'Salvar'"
          />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ProfileService from '../services/profile.service'
import ContainerVue from '@/components/Container.vue'
import PasswordFormVue from './PasswordForm.vue'
import Form from './Form.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import * as toast from '@/helpers/useToast'
import * as yup from './../../../validator'
import { useRoute } from 'vue-router'
import { dataUser } from '../store'
import { storeApp } from '@/store'
import { onMounted } from 'vue'

const store = dataUser
const appStore = storeApp.state
const meUuid = useRoute()

const schema = yup.object().shape({
  name: yup.string().label('Nome').required().min(3, 'O nome precisa de no minimo 3 caracteres'),
  role: yup.string().label('Função').nullable(),
  email: yup.string().email().label('Email').required().min(5, 'O e-mail precisa de no minimo 5 caracteres'),
  phone: yup.string().label('Celular').nullable()
})

const password = yup.object().shape({
  password: yup.object().shape({
    old: yup.string().min(5, 'Inválido').required('Por favor informe a senha anterior'),
    newPass: yup.string().required('É necessário informar a nova senha').min(5, 'Deve ter no mínimo 5 caracteres'),
    confirm: yup.string().oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais').required('Por favor confirme a nova senha')
  })
})

onMounted(async () => {
  store.commit('reset')
  appStore.loading = true
  await getMeData()
  appStore.loading = false
})

async function getMeData () {
  await ProfileService.getMeProfile(localStorage.getItem('__uuid'))
    .then(res => {
      store.commit('updateUserFields', res.data)
    })
    .catch(err => {
      toast.showToast(
        'Aviso', 
        err.response.data.error, 
        'error'
      )
    })
}

async function editMe () {
  appStore.loading = true

  const data = Object.assign({}, store.state)

  let validatePassword
  if (store.state.password.newPass !== undefined) { validatePassword = (await validateFormField(password, store.state)) } else {
    delete data.password
  }

  if (validatePassword && validatePassword.length > 0) {
    setFormFieldsError(validatePassword)
    appStore.loading = false
    return
  }

  const validate = (
    await validateFormField(schema, store.state)
  )

  if (validate && validate.length > 0) {
    appStore.loading = false
    setFormFieldsError(validate)
    return
  }

  if ( // se caso exista uma foto, remova e troque pela nova
    data.image &&
    typeof data.image === 'object' &&
    typeof data.imgPath === 'string' &&
    data.imgPath !== '[]'
  ) {
    await ProfileService.editImage(
      data.image,
      data.imgPath
    )
      .then((res) => {
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast.showToast(
          'Aviso', 
          'Algo deu errado ao subir arquivo!', 
          'error'
        )
      })
  } else if ( // adicione uma nova foto
    data.image &&
    typeof data.image !== 'string'
  ) {
    await ProfileService.saveImage(data.image)
      .then((res) => {
        data.imgPath = res.data
        data.image = res.data
      })
      .catch((err) => {
        toast.showToast(
          'Aviso', 
          'Algo deu errado ao subir arquivo!', 
          'error'
        )
      })
  } else { // passa o caminho da imagem
    data.image = data.imgPath
  }

  delete data.imgPath
  delete data.haircuts_count
  delete data.collabs
  delete data.deleted_at

  await ProfileService.updateMe(meUuid.params.id, data)
    .then((res) => {
      if (res.data.success) {
        toast.showToast(
          'Sucesso', 
          'Dados atualizados com sucesso!', 
          'success'
        )
      }
      appStore.loading = false
    })
    .catch((err) => {
      toast.showToast(
        'Aviso', 
        err.response.data.error, 
        'error'
      )
      appStore.loading = false
    })
}

</script>
