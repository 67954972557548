<template>
  <div>
    <PageTitleVue> Meus dados </PageTitleVue>

    <ContainerVue>

      <Tabs
        :label1="'Dados da empresa'"
        :label2="'Mais configurações'"
        :icon1="'fa-regular fa-building'"
        :icon2="'fa-solid fa-gears'"
      >
        <!-- dados da empresa -->
        <template #firstTab>
          <Form id="form-fields" :description-title="'Dados da empresa'">
            <div class="w-full flex justify-center mt-4">
              <CreateButton
                @click="saveData"
                :icon="'fa-solid fa-check'"
                :label="'Salvar'"
              />
            </div>
          </Form>
        </template>

        <template #secondTab>
          <ConfigForm
            :titleFirstContainer="'Horarios de funcionamento'"
            :descriptionFirstContainer="'Informe os horários de funcionamento da sua barbearia'"
            :titleSecondContainer="'Agendamentos'"
            :descriptionSecondContainer="'Defina os horários e intervalos para seus agendamentos'"
          >
            <div class="w-full flex justify-center">
              <CreateButton
                @click="saveConfigs"
                :icon="'fa-solid fa-check'"
                :label="'Salvar'"
              />
            </div>
          </ConfigForm>
        </template>
      </Tabs>
    </ContainerVue>

    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import Tabs from '@/modules/costs/components/TabsVue.vue'
import CompanyService from '../services/company.service'
import ContainerVue from '@/components/Container.vue'
import ConfigForm from './ConfigForm.vue'
import Form from './Form.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import * as toast from '@/helpers/useToast'
import { useRoute } from 'vue-router'
import { dataUser } from '../store'
import { storeApp } from '@/store'
import { onMounted, onUpdated } from 'vue'
import * as yup from './../../../validator'

const store = dataUser
const appStore = storeApp.state
const meUuid = useRoute()

const schema = yup.object().shape({
  company: yup.object().shape({
    name: yup.string().required().label('Nome Fantasia').max(100).min(2),
    cnpj: yup.string().label('CNPJ').min(18),
    city: yup.string().label('Cidade'),
    number: yup.number().integer().label('Número').min(1)
  })
})

onMounted(async () => {
  appStore.loading = true
  store.commit('reset')
  await Promise.all([
    getCompanyConfigs(),
    getDataCompany()
  ])
  appStore.loading = false
})

async function getDataCompany () {
  await CompanyService.getMeCompany(meUuid.params.id)
    .then((res) => {
      store.state.company.name = res.data.name
      store.state.company.cnpj = res.data.CNPJ
      store.state.company.city = res.data.city
      store.state.company.address = res.data.address
      store.state.company.country = res.data.country
      store.state.company.number = res.data.number
      store.state.imgPath = res.data.photo
    })
    .catch((err) => {
      toast.showToast(
        'Aviso',
        'Algo deu errado ao buscar dados da empresa', 
        'error'
      )
    })
}

// salva os dados da empresa
async function saveData () {
  appStore.loading = true

  const validate = (await validateFormField(schema, store.state))
  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await Promise.all([
    editCompany()
  ])
  appStore.loading = false
}

// pegas as cofigurações de horários
async function getCompanyConfigs () {
  await CompanyService.getConfigs()
    .then(res => {
      store.dispatch('setFieldConfigs', res.data)
    }).catch(err => {
      if (err.response.data.status && err.response.data.status === 400) {
        return toast.showToast(
          'Aviso',
          err.response.data.message, 
          'warning'
        )
      }

      return toast.showToast(
        'Aviso',
        'Algo deu errado ao buscar configurações', 
        'error'
      )
    })
}

// salva as configurações de horário
async function saveConfigs () {
  appStore.loading = true

  Promise.allSettled([
    CompanyService.updateConfigs(store.state.configs)
      .then(res => {
        appStore.loading = false

        if (res.data.success) {
          toast.showToast(
            '',
            res.data.message, 
            'success'
          )
        }
      }).catch(err => {
        appStore.loading = false
        if (err.response.data.status && err.response.data.status === 404) {
          return toast.showToast(
            'Aviso',
            err.response.data.message, 
            'warning'
          )
        }
        if (err.response.data.status && err.response.data.status === 400) {
          return toast.showToast(
            'Aviso',
            err.response.data.message, 
            'warning'
          )
        }

        return toast.showToast(
          'Aviso',
          'Algo deu errado ao fazer agendamento', 
          'error'
        )
      })
  ])
}

// edita os dados e atualiza a foto
async function editCompany () {
  if ( // se caso exista uma foto, remova e troque pela nova
    store.state.company.photo &&
    typeof store.state.company.photo === 'object' &&
    typeof store.state.imgPath === 'string' &&
    store.state.imgPath !== '[]'
  ) {
    await CompanyService.editImage(
      store.state.company.photo,
      store.state.imgPath
    )
      .then((res) => {
        store.state.imgPath = res.data
        store.state.company.photo = res.data

        storeApp.dispatch('setCompanyLogo', res.data)
      })
      .catch((err) => {
        toast.showToast(
          'Aviso',
          'Algo deu errado ao subir arquivo!', 
          'warning'
        )
      })
  } else if ( // adicione uma nova foto
    store.state.company.photo &&
    typeof store.state.company.photo !== 'string'
  ) {
    await CompanyService.saveImage(store.state.company.photo)
      .then((res) => {
        store.state.imgPath = res.data
        store.state.company.photo = res.data

        storeApp.dispatch('setCompanyLogo', res.data)
      })
      .catch((err) => {
        toast.showToast(
          'Aviso',
          'Algo deu errado ao subir arquivo!', 
          'warning'
        )
      })
  } else { // passa o caminho da imagem
    store.state.company.photo = store.state.imgPath
  }

  // atualiza os dados da empresa
  await CompanyService.updateMeCompany(meUuid.params.id, store.state.company)
    .then((res) => {
      if (res.data.success) {
        toast.showToast(
          'Sucesso',
          'Dados da empresa atualizados', 
          'success'
        )
      }
    })
    .catch((err) => {
      toast.showToast(
        'Aviso',
        'Algo deu errado ao atualizar dados!', 
        'error'
      )
    })
}

</script>