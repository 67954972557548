<template>
  <div class="w-full px-2 sm:px-0 mt-2 md:mt-0">
    <TabGroup>
      <TabList class="flex space-x-1 rounded-xl bg-gray-100 p-1">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 color-app',
              'ring-white/60 ring-offset-2 ring-offset-green-app focus:outline-none focus:ring-2',
              selected
                ? 'bg-white active-tab shadow'
                : 'text-blue-100 hover:text-[#177835]',
            ]"
          >
            <div class="flex items-center justify-center">
              <span>
                <i :class="icon1"></i>
              </span>
              <h2 class="ml-1">
                {{ props.label1 }}
              </h2>
            </div>
          </button>
        </Tab>

        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 color-app',
              'ring-white/60 ring-offset-2 ring-offset-green-app focus:outline-none focus:ring-2',
              selected
                ? 'bg-white active-tab shadow'
                : 'text-blue-100 hover:text-[#177835]',
            ]"
          >
            <div class="flex items-center justify-center">
              <span>
                <i :class="icon2"></i>
              </span>
              <h2 class="ml-1">
                {{ label2 }}
              </h2>
            </div>
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-2">
        <TabPanel
          :class="[
            'rounded-xl bg-white p-3',
            'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
          ]"
        >
          <slot name="firstTab" />
        </TabPanel>

        <TabPanel
          :class="[
            'rounded-xl pl-0 pr-0 p-3 md:p-3',
            'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
          ]"
        >
          <slot name="secondTab" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

const props = defineProps({
  label1: { String, default: 'Geral' },
  label2: { String, default: 'Lançar custo' },
  icon1: {
    String,
    default: 'fas fa-plus'
  },
  icon2: {
    String,
    default: 'fas fa-plus'
  }
})
</script>

<style lang="scss" scoped>
.ring-offset-green-app {
  --tw-ring-offset-color: #1e7c84;
}
.color-app {
  color: #2c3e50;
  font-weight: bold;
}
.active-tab {
  color: #1e7c84;
  text-shadow: 5px 5px 15px #ccc;
}
</style>
