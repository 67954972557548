import { useToast } from 'vue-toastification'
import ToastContent from './../components/ToastContent.vue'

const toast = useToast()

/**
 * Função para exibir toast com título, mensagem e ícone
 * @param {String} title - O título do toast
 * @param {String} message - A mensagem do toast
 * @param {Object} icon - Componente Vue para o ícone (Heroicon)
 * @param {Object} options - Configurações extras do toast (opcional)
 */
export function showToast (title, message, icon = null, options = {}) {
  toast(
    {
      component: ToastContent,
      props: {
        title,
        message,
        icon
      }
    },
    {
      toastClassName: `toast-${icon}`,
      timeout: 5000,
      ...options // configurações adicionais
    }
  )
}
