<template>
  <div class="toast-container">
    <div v-if="currentIcon" class="toast-icon">
      <component :is="currentIcon" class="h-6 w-6" />
    </div>
    <div>
      <h3 class="toast-title">{{ title }}</h3>
      <p class="toast-message">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
  XCircleIcon
} from '@heroicons/vue/outline'

export default {
  components: {
    CheckCircleIcon,
    InformationCircleIcon,
    ExclamationIcon,
    XCircleIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    currentIcon () {
      const icons = {
        success: CheckCircleIcon,
        info: InformationCircleIcon,
        warn: ExclamationIcon,
        error: XCircleIcon
      }
      return icons[this.icon] || icons.info
    }
  }
}
</script>

<style scoped>
.toast-container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.toast-icon {
  flex-shrink: 0;
  color: inherit; /* Herda a cor do tipo do toast */
}
.toast-title {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 4px;
}
</style>
