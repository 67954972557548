<template>
  <div class="z-10">
    <PageTitleVue> Inicio </PageTitleVue>

    <!-- data -->
    <div class="calendar-container">
      <div class="calendar-header">

        <button @click="prevMonth" class="nav-button ml-2">
          <ChevronDownIcon
            class="h-7 w-7 text-gray-400 rotate-90"
          />
        </button>
        <span class="month-year">{{ monthYear }}</span>
        <button @click="nextMonth" class="nav-button mr-2">
          <ChevronDownIcon
            class="h-7 w-7 text-gray-400 -rotate-90"
          />
        </button>

      </div>
    </div>

    <!-- cards -->
    <div
      class="w-full mb-1 -mt-3"
      :class="[
        isMobile ? 'block font-medium' : 'flex justify-center font-semibold'
      ]"
    >
      <!-- entrada -->
      <TotalAmount
        :label="'Total de entrada'"
        :value="formatPrice(totalAmount)"
        :totalAmount="true"
      />

      <!-- saída -->
      <TotalAmount
        :label="'Total de saida'"
        :value="formatPrice(totalCosts)"
        :totalCosts="true"
      />

      <!-- serviços -->
      <TotalAmount
        :label="'Serviços realizados'"
        :value="totalServices"
        :totalServices="true"
      />

      <!-- agendamentos -->
      <!-- <TotalAmount
        :label="'Agendamntos'"
        :value="totalServices"
        :totalServices="true"
      /> -->
    </div>

    <!-- services chart -->
    <div
      class="p-1 rounded-2xl"
      :class="[
        isMobile ? 'block' : 'flex'
      ]"
    >
      <TheServicesChart
        :key="servicesByMonth"
        :labels="Object.keys(formatAndSortData(servicesByMonth))"
        :services="totalServices"
        :data="{
          label: 'Serviços por Dia',
          data: formatAndSortData(servicesByMonth),
          borderColor: '#42A5F5',
          backgroundColor: 'rgba(66, 165, 245, 0.2)'
        }"
      />
    </div>

    <!-- <div v-else>
      <NoData 
        :width="300" 
        :height="300" 
        :label="'Nenhum serviço realizado esse mês'" 
      />
    </div> -->

    <hr>

    <!-- chart -->
    <div
      class="p-1 rounded-2xl"
    >
      <TheLineChart
        :key="barberPerPage()"
        :labels="months"
        :data="barberPerPage()"
      />

      <div 
        class="p-2 border rounded-2xl text-left min-w-[25%] w-full" 
      >
        <!-- title -->
        <!-- <div class="w-full text-center -mt-3 mb-3 text-xl font-semibold">
          <h1>
            Equipe
          </h1>
        </div> -->

        <!-- barbers -->
        <div class="max-h-[50vh] overflow-y-auto">
          <div v-for="(item, index) in barberPerPage()" :key="index">
            <div
              class="flex font-medium p-2 rounded-2xl border-b-[#ccc]">
              <UserCircleIcon
                class="my-auto w-10 h-10 mr-5 rounded-full"
                :class="[
                  `text-[${item.backgroundColor}]`
                ]"
              />
              <span>
                {{
                  item.name.length > 15 ?
                  item.name.slice(0, 15).concat("...") : 
                  item.name
                }} <br>
                <small>
                  serviços: {{ item.services }}
                </small>
              </span>
            </div> <hr>
          </div>
        </div>

        <!-- footer -->
        <div v-if="barbersData.length >= 5" class="w-full text-center">
          <div class="w-full mt-6 mb-1 font-semibold flex justify-center">
            Mostrando
            <input
              class="border border-[#ccc] rounded min-w-[8.333333%] w-1/12 text-center mx-1"
              type="text"
              v-model="limitBarbers"
            >
            colaboradores
          </div>
          <small>
            total de {{ barbersData.length }}
          </small>
        </div>
      </div>
    </div>

    <!-- <div v-else>
      <NoData 
        :width="300" 
        :height="300" 
        :label="'Sem registro de funcionários'" 
      />
    </div> -->

    <!-- <FloatCreateVue
      :link="'releases'"
      :menuActive="'Serviços'"
      :icon="'fas fa-plus'"
    /> -->
  </div>
</template>

<script setup>
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import TheLineChart from './components/TheLineChart.vue'
import TheServicesChart from './components/TheServicesChart.vue'
import NoData from '@/components/Lotties/NoDataAnim.vue'
import TotalAmount from './components/TotalAmount.vue'
import HomeService from './services/home.service'
import { onBeforeMount, ref, computed, watch } from 'vue'
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/vue/outline'
import { storeApp } from '@/store'
import { dataHome } from './store'
import * as toast from '@/helpers/useToast'

const currentDate = ref(new Date())
const appStore = storeApp
const store = dataHome

// const weekdays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']
const monthsFilter = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
const daysInMonth = ref([])

// chart
const limitBarbers = ref(5)
const barbersData = ref([])
const servicesByMonth = ref([])
const months = ref(['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'])

// cards
const totalServices = ref('0')
const totalAmount = ref('0')
const totalCosts = ref('0')

const isMobile = computed(() => window.innerWidth < 768)
const monthYear = computed(() => {
  const month = currentDate.value.getMonth()
  const year = currentDate.value.getFullYear()
  return `${monthsFilter[month]} ${year}`
})

const barberPerPage = () => {
  const limit = limitBarbers.value && limitBarbers.value > 0 ? limitBarbers.value : barbersData.value.length

  return barbersData.value.slice(0, limit)
}

const generateRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

watch(limitBarbers, () => {
  barberPerPage()
})

watch(currentDate, updateDaysInMonth, { immediate: true })

onBeforeMount(async () => {
  await getDataChart()
})

async function getDataChart (params) {
  appStore.state.loading = true

  await HomeService.getDataChartTable(params?.month, params?.year)
    .then(res => {
      if (res.data) {
        const barbers = res.data.barbers

        const formattedBarbersData = []
        const predefinedColors = [
          '#009285',
          '#B45A5A',
          '#B4955A',
          '#5a5ab4',
          '#a45ab4'
        ]

        for (const [index, [barberName, barberData]] of Object.entries(barbers).entries()) {
          // Usa uma cor pré-definida para os primeiros 5, depois gera cores aleatórias
          const color = index < predefinedColors.length ? predefinedColors[index] : generateRandomColor()

          formattedBarbersData.push({
            label: barberName.slice(0, 10).concat('...'),
            name: barberName,
            services: barberData.totalService,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            fill: false,
            data: Object.values(barberData.servicesByMonth).map(value => String(value)) // Converte valores mensais para string
          })
        }

        servicesByMonth.value = res.data.servicesByMonth
        barbersData.value = formattedBarbersData
        totalServices.value = res.data.servicesPerformed
        totalAmount.value = res.data.totalAmount
        totalCosts.value = res.data.totalCosts
      }

      appStore.state.loading = false
    }).catch(err => {
      appStore.state.loading = false

      toast.showToast(
        'Aviso',
        'Algo deu errado ao buscar dados do gráfico',
        'error'
      )
    })
}

function formatPrice (value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(value)
}

async function nextMonth () {
  const month = currentDate.value.getMonth()
  if (month === 11) {
    currentDate.value = new Date(currentDate.value.getFullYear() + 1, 0, 1)
  } else {
    currentDate.value = new Date(currentDate.value.getFullYear(), month + 1, 1)
  }

  Promise.allSettled([
    await getDataChart({
      month: currentDate.value.getMonth() + 1,
      year: currentDate.value.getFullYear(),
    })
  ])

  updateDaysInMonth()
}

async function prevMonth () {
  const month = currentDate.value.getMonth()
  if (month === 0) {
    currentDate.value = new Date(currentDate.value.getFullYear() - 1, 11, 1)
  } else {
    currentDate.value = new Date(currentDate.value.getFullYear(), month - 1, 1)
  }

  Promise.allSettled([
    await getDataChart({
      month: currentDate.value.getMonth() + 1,
      year: currentDate.value.getFullYear(),
    })
  ])

  updateDaysInMonth()
}

function updateDaysInMonth () {
  const year = currentDate.value.getFullYear()
  const month = currentDate.value.getMonth()
  const days = []

  const firstDayOfMonth = new Date(year, month, 1).getDay()
  const totalDays = new Date(year, month + 1, 0).getDate()

  for (let i = 0; i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1); i++) {
    days.push({ date: null, label: '' })
  }

  for (let day = 1; day <= totalDays; day++) {
    days.push({
      date: new Date(year, month, day),
      label: day
    })
  }

  daysInMonth.value = days
}

function formatAndSortData(data) {
  const dataArray = Object.entries(data)

  dataArray.sort((a, b) => {
    const [dayA, monthA] = a[0].split('/').map(Number)
    const [dayB, monthB] = b[0].split('/').map(Number)

    if (monthA !== monthB) {
      return monthA - monthB
    }
    return dayA - dayB
  })

  const sortedData = Object.fromEntries(dataArray)

  return sortedData
}

</script>

<style scoped lang="scss">
.calendar-container {
  margin: 10px auto;
  padding-bottom: -10px;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
}

.nav-button {
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

</style>
