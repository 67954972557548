<template>
  <div
    class="chart w-full overflow-x-auto"
  >
    <h2 class="text-center text-xl font-semibold mb-4">Serviços do Mês</h2>
    <Line
      class="overflow-x-auto w-full"
      id="chart"
      ref="chart"
      :data="chartData"
      :options="chartOptions"
      :height="450"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement)

export default {
  name: 'ChartComponent',
  props: {
    labels: Array,
    data: Array,
    services: [String, Number]
  },
  components: {
    Line
  },
  data () {
    return {
      chartData: {
        labels: this.labels,
        datasets: [{
          label: 'Serviços',
          data: this.data.data,
          borderColor: this.data.borderColor,
          backgroundColor: this.data.backgroundColor,
          tension: 0.3,
          borderWidth: 2,
          pointRadius: 4,
        }]
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top'
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Data da realização'
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              // text: 'Data de realização'
            },
            ticks: {
              precision: 0
            }
          }
        }
      }
    }
  },
  watch: {
    data () {
      this.updateChartData()
    },
    labels () {
      this.chartData.labels = this.labels
      this.refreshChart()
    }
  },
  methods: {
    updateChartData () {
      this.chartData.datasets = this.data
      this.refreshChart()
    },
    refreshChart () {
      this.$nextTick(() => {
        const chart = this.$refs.chart
        if (chart && chart.chart) { chart.chart.update() }
      })
    },
  }
}
</script>

<style scoped>
.chart {
  margin: 1rem auto 1rem;
  overflow: hidden;
}
</style>
