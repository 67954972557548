import { http } from '@/http'

const PATH = 'app/'

class ScheduleService {
  async getAllSchedules (permission) {
    return http.get(PATH.concat('company/schedules'), {
      headers: {
        __permission: permission,
        __uuid: localStorage.getItem('__uuid'),
        __company_id: localStorage.getItem('__company')
      }
    })
  }

  async getDataFields () {
    return http.get(PATH.concat('data-fields'))
  }

  async register (data) {
    return http.post(PATH.concat('new-haircut'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async getDataClientSchedule (hash) {
    return http.get(PATH.concat('scheduling/').concat(hash))
  }

  async verifyUnavailableTimes (date, cId) {
    return http.get(PATH.concat('scheduling/').concat(`unavailable-times?date=${date}&cId=${cId}`))
  }

  // client
  async registerSchedule (data, cId) {
    return http.post(PATH.concat('scheduling/to-schedule'), data, {
      headers: {
        __company: cId
      }
    })
  }

  async removeSchedule (id) {
    return http.delete(PATH.concat('company/schedules/').concat(id).concat('/remove'), {
      headers: {
        __uuid: localStorage.getItem('__uuid')
      }
    })
  }

  async registerService (data) {
    return http.post(PATH.concat('new-haircut/schedule'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }
}

export default new ScheduleService()
