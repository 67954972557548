import { createStore } from 'vuex'

export const dataReleases = createStore({
  state: () => {
    return {
      method: undefined,
      clients: undefined,
      products: undefined,
      note: null,
      dataItens: []
    }
  },
  getters: {
    getDataitens: state => state.dataItens
  },
  mutations: {
    reset (state) {
      state.method = undefined
      state.clients = undefined
      state.products = undefined
      state.note = null
      state.dataItens = []
    },
    setFields (state, payload) {
      Object.assign(state, payload)
    },
    mountedItensToSend (state, val) {
      state.dataItens.push(val)
    }
  },
  actions: {
    setDataItens ({ commit }, val) {
      commit('mountedItensToSend', val)
    }
  }
})
