<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>

        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

            <div class="text-left mb-2">
              <p class="mb-2 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-3">
                  <BaseInput
                    name="name"
                    :info="'Nome do produto ou serviço'"
                    v-model="store.name"
                    :required-field="true"
                    label="Nome do produto"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.name = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="name" class="text-red-600 text-sm" />
                </div>

                <!-- estoque -->
                <div class="md:col-span-2">
                  <BaseInput
                    type="number"
                    name="price"
                    v-model="store.amount"
                    :info="'Informe estoque desse produto'"
                    label="Quantidade em estoque"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.amount = null"
                      />
                    </template>
                  </BaseInput>
                </div>

                <!-- valor -->
                <div class="md:col-span-3">
                  <BaseInput
                    name="price"
                    :info="'Valor do produto ou serviço'"
                    v-model="store.price"
                    label="Valor R$"
                    type="text"
                    @input="formatCurrency"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.price = null"
                      />
                    </template>
                  </BaseInput>

                  <span name="price" class="text-red-600 text-sm" />
                </div>

                <!-- tipo de produto -->
                <div class="md:col-span-2 mt-1" :key="store.products">
                  <label class="text-left flex pl-1 text-xs text-[#2b3038]" for="type">
                    Tipo
                  </label>
                  <div
                    class="h-10 flex rounded items-center"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      name="type"
                      :initialValue="store.type"
                      :data="types"
                      @selected-data="(val) => (store.type = val)"
                    />
                  </div>

                  <span name="type" class="text-red-600 text-sm" />
                </div>

                <!-- descrição -->
                <div class="md:col-span-5">
                  <BaseInput
                    v-model="store.description"
                    name="description"
                    :info="'Adicione uma descrição do serviço/produto'"
                    label="Descrição"
                    type="text"
                  >
                    <template #append>
                      <CleanInputButton
                        @click="store.description = null"
                      />
                    </template>
                  </BaseInput>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from '../components/CleanInputButton.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { dataProduct } from '../store/'
import { defineProps, ref } from 'vue'

const store = dataProduct.state
const rawPrice = ref('')
const props = defineProps({
  descriptionTitle: String
})

const types = ref([
  {
    name: 'SERVIÇO',
    value: 'service'
  },
  {
    name: 'PRODUTO',
    value: 'product'
  }
])

const formatCurrency = (event) => {
  const onlyDigits = event.target.value.replace(/\D/g, '')

  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(onlyDigits / 100)

  store.price = formatted
  rawPrice.value = onlyDigits / 100
}

</script>
