<template>
    <div class=" flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>

          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

              <div class="text-left mb-2">
                <p class="mb-2 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  {{ descriptionSubTitle }}
                </p>
              </div>

              <div class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <!-- turnos de atendimento -->
                  <div
                    class="md:col-span-2"
                    :class="{ 'md:col-span-3': ! store.turno.is_freela, 'md:col-span-2': store.turno.is_freela }"
                  >
                    <label class="flex pl-1 text-xs text-[#2b3038]">
                      Informe os períodos
                    </label>
                    <div class="h-10 flex rounded items-center mt-1">
                      <SimpleSelect
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                        :initialValue="store.turno.shiftHour"
                        :data="shiftHours"
                        @selected-data="
                          (val) => {
                            if (val?.value == 'FULL_TIME'){
                              store.turno.shiftHour = 'full_time';
                              store.turno.is_freela = false;
                            } else if (val?.value == 'MORNING_ONLY') {
                              store.turno.shiftHour = 'morning_only';
                              store.turno.is_freela = false;
                            } else if (val?.value == 'AFTERNOON_ONLY') {
                              store.turno.shiftHour = 'afternoon_only';
                              store.turno.is_freela = false;
                            } else {
                              store.turno.shiftHour = 'free_lancer';
                              store.turno.is_freela = true;
                            }
                        }
                        "
                      />
                    </div>
                  </div>

                  <!-- se free lancer -->
                  <Transition mode="out-in">
                    <div class="lg:col-span-2" v-if="store.turno.is_freela">
                      <div class="grid gap gap-y-2 text-sm grid-cols-1 md:grid-cols w-full">

                        <!-- freelancer estadispojnivel disponível? -->
                        <div class="md:col-span-2">
                          <label class="text-left flex pl-1 text-xs text-[#2b3038]">
                            O Free Lancer esta em serviço hoje?
                          </label>
                          <div class="h-10 flex rounded items-center mt-1">
                            <SimpleSelect
                              class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                              :initialValue="store.turno.freela_in_service ? '1' : '0'"
                              :data="freeLancerActive"
                              @selected-data="
                                (val) => {
                                  if (val?.value == '1'){
                                    store.turno.freela_in_service = true
                                  } else {
                                    store.turno.freela_in_service = false
                                  }
                                }
                              "
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </Transition>

                  <!-- horario de entrada -->
                  <div class="md:col-span-2 -mt-1">
                    <BaseInput
                      v-model="store.turno.entry_time"
                      name="turno.entry_time"
                      :info="'Inicio do expediente'"
                      label="Inicio do expediente"
                      type="time"
                      :required-field="false"
                      :center="true"
                      :activeDefault="true"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.turno.entry_time = null"
                        />
                      </template>
                    </BaseInput>
                  </div>

                  <!-- horario de saída -->
                  <div class="md:col-span-2 -mt-1">
                    <BaseInput
                      v-model="store.turno.closing_time"
                      name="turno.closing_time"
                      :info="'Fim do expediente'"
                      label="Fim do expediente"
                      type="time"
                      :required-field="false"
                      :center="true"
                      :activeDefault="true"
                    >
                      <input type="" />
                      <template #append>
                        <CleanInputButton
                          @click="store.turno.closing_time = null"
                        />
                      </template>
                    </BaseInput>
                  </div>

                  <!-- almoço -->
                  <div class="md:col-span-2 -mt-1">
                    <BaseInput
                      v-model="store.turno.hourForLunch"
                      name="turno.hourForLunch"
                      :info="'Informe o horário de almoço'"
                      label="Almoço"
                      type="time"
                      :required-field="false"
                      :center="true"
                      :activeDefault="true"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.turno.hourForLunch = null"
                        />
                      </template>
                    </BaseInput>
                  </div>

                  <!-- retorno do almoço -->
                  <div class="md:col-span-2 -mt-1">
                    <BaseInput
                      v-model="store.turno.returnForLunch"
                      name="turno.returnForLunch"
                      :info="'Retorno do almoço'"
                      label="Retorno"
                      type="time"
                      :required-field="false"
                      :center="true"
                      :activeDefault="true"
                    >
                      <input type="" />
                      <template #append>
                        <CleanInputButton
                          @click="store.turno.returnForLunch = null"
                        />
                      </template>
                    </BaseInput>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>

        </div>
      </div>
    </div>
  </template>

<script setup>
import BaseInput from '@/components/Inputs/BaseInput.vue'
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataCollab } from '../store'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import { onMounted } from 'vue'

defineProps({
  descriptionTitle: String,
  descriptionSubTitle: String
})

const store = dataCollab.state
const freeLancerActive = [
  {
    name: 'SIM',
    value: '1'
  },
  {
    name: 'NÂO',
    value: '0'
  }
]
const shiftHours = [
  {
    name: 'PERIODO INTEGRAL',
    value: 'FULL_TIME'
  },
  {
    name: 'APENAS DE MANHÃ',
    value: 'MORNING_ONLY'
  },
  {
    name: 'APENAS DE TARDE',
    value: 'AFTERNOON_ONLY'
  },
  {
    name: 'FREE LANCER',
    value: 'FREE_LANCER'
  }
]

onMounted(() => {
  const keys = [
    'shiftHour',
    'is_freela',
    'freela_in_service',
    'hourForLunch',
    'returnForLunch',
    'entry_time',
    'closing_time'
  ]

  keys.forEach((key) => {
    const getterKey = `get${key}`
    store.turno[key] = dataCollab.getters[getterKey] || undefined
  })
})

</script>
