<template>
    <div class=" flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>

          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

              <div class="text-left ">
                <p class="mb-2 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  {{ descriptionSubTitle }}
                </p>
              </div>

              <div class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <!-- senha anterior -->
                  <div v-show="! create" class="md:col-span-3">
                    <BaseInput
                      v-model="store.password.old"
                      name="old"
                      :info="'Informe a senha anterior'"
                      label="Senha anterior"
                      type="password"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.old = undefined"
                        />
                      </template>
                    </BaseInput>
                    <span name="old" class="text-red-600 text-sm" />
                  </div>

                  <!-- nova senha -->
                  <div :class="{ 'md:col-span-2': ! create, 'md:col-span-5': create }">
                    <BaseInput
                      v-model="store.password.newPass"
                      name="password.newPass"
                      :info="'Defina uma nova senha'"
                      label="Nova senha"
                      type="password"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.newPass = undefined"
                        />
                      </template>
                    </BaseInput>

                    <span name="password.newPass" class="text-red-600 text-sm" />
                  </div>

                  <!-- confirmar senha -->
                  <div class="md:col-span-5">
                    <BaseInput
                      v-model="store.password.confirm"
                      name="password.confirm"
                      :info="'Confirme a nova senha'"
                      label="Confirmar senha"
                      type="password"
                    >
                      <template #append>
                        <CleanInputButton
                          @click="store.password.confirm = undefined"
                        />
                      </template>
                    </BaseInput>
                    <span name="password.confirm" class="text-red-600 text-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>

        </div>
      </div>
    </div>
  </template>

<script>
import BaseInput from '@/components/Inputs/BaseInput.vue'
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataCollab } from '../store'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    BaseInput
  },
  props: {
    descriptionTitle: String,
    descriptionSubTitle: String,
    create: Boolean
  },
  data () {
    const store = dataCollab.state

    return {
      store,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  }
}
</script>
